import axios from "@/axios.config";
import packages from "./packages";
import Swal from "sweetalert2";

const state = {
  solicitation: [],
  imeis: [],
  accounts: [],
  selected_solicitation: {},
  filter: "",
  type: "",
  solicitation_status: [],
  solicitation_type: [],
  chip_values: [],
  selected_solicitation_type: {},
  selected_solicitation_values: {},
  selected_solicitation_validate_fields: {},
  solicitation_form_email: {},
  solicitation_values: {},
  solicitation_forms: {},
  approve_error: {
    success: false,
    message: ""
  },
  approve_success: {
    success: false,
    message: ""
  },
  approvers: {},
  protocols: {},
  wizard_data: [],
  solicitation_validate_values: {},
  solicitation_validate_plans_values: {},
  solicitation_validate_packages_values: {},
  solicitation_validate_remove_packages_values: [],
  solicitation_validate_cancel_line_values: [],
  solicitation_validate_change_chip_values: [],
  solicitation_validate_online_manager_values: [],
  solicitation_validate_suspend_line_values: [],
  solicitation_validate_ddd_values: {},
  solicitation_validate_status: [],
  package_component_value: [],
  packages_to_disable_component_values: [],
  cancel_line_component_value: [],
  suspend_line_component_value: [],
  change_chip_component_value: [],
  online_manager_component_value: [],
  reactive_line_component_value: [],
  voluntary_line_suspension_component_value: [],
  activate_new_line_component_value: [],
  status_list: [],
  total_items: 0,
  total_pages: 0,
  dataExcel: []
};

const actions = {
  async loadSolicitation({ commit }, search) {
    try {
      let params = new URLSearchParams();
      params.append('page', search.page);
      params.append('per_page', search.per_page);
      params.append('search', search.search || '');
      params.append('sort_by', search.sort_by || '');
      params.append('sort_order', search.sort_order || '');

      if (search.search_column) {
        for (const key in search.search_column) {
          if (Array.isArray(search.search_column[key])) {
            search.search_column[key].forEach(value => {
              params.append(`${key}[]`, value);
            });
          } else {
            params.append(key, search.search_column[key]);
          }
        }
      }
      const { data } = await axios.get("/solicitation", { params });
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSolicitationExcel({ commit }, search) {
    try {
      let params = new URLSearchParams();
      params.append('page', search.page);
      params.append('per_page', search.per_page);
      params.append('search', search.search || '');
      params.append('sort_by', search.sort_by || '');
      params.append('sort_order', search.sort_order || '');
      params.append('export', true);

      if (search.search_column) {
        for (const key in search.search_column) {
          if (Array.isArray(search.search_column[key])) {
            search.search_column[key].forEach(value => {
              params.append(`${key}[]`, value);
            });
          } else {
            params.append(key, search.search_column[key]);
          }
        }
      }
      const { data } = await axios.get("/solicitation", { params });
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_DATA_EXCEL", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadImeis({ commit }) {
    try {
      const { data } = await axios.get("/solicitation/imei");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_IMEIS", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadAccountsJoin({ commit }) {
    try {
      const { data } = await axios.get("/solicitation/accounts");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_ACCOUNTS", { accounts: data.data });
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSelectedSolicitation({ commit }, solicitation_id) {
    const { data } = await axios.get("/solicitation/" + solicitation_id);
    commit("SET_SELECTED_SOLICITATION", data);
  },
  async saveSolicitation({ dispatch }, solicitation) {
    try {
      const { data } = await axios.post("/solicitation/", solicitation);
      if (!data.success) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadApprover({ commit }, solicitationId) {
    const { data } = await axios.get("/approvers/" + solicitationId);
    commit("SET_APPROVER", data);
  },

  async loadProtocols({ commit }, solicitation) {
    const { data } = await axios.get("/solicitation/protocols/" + solicitation.id);
    commit("SET_PROTOCOLS", data.data);
  },

  async saveProtocol({ dispatch }, payload) {
    try {
      const { data } = await axios.post("/solicitation/protocols/add/", payload);
      if (!data.success) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteProtocol({ dispatch }, protocol) {
    try {
      const { data } = await axios.delete(`/solicitation/protocols/delete/${protocol.id}`);
      if (!data.success) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSolicitationType({ commit }, solicitation_type_id) {
    await axios
      .post("/solicitation/wizard", { type: solicitation_type_id })
      .then((response) => {
        commit("setSolicitationForms", response.data);
      })
      .catch();
  },
  async loadSolicitationStatus({ commit }) {
    const { data } = await axios.get("/solicitation_status?screen=interaction");
    commit("SET_SOLICITATION_STATUS", data);
  },

  async validateSolicitation({ commit, state }, body) {
    try {
      body.fields = { ...body.fields };
      if (
        Object.keys(state.solicitation_validate_plans_values).length !== 0 &&
        state.solicitation_validate_plans_values.constructor === Object
      ) {
        body.fields.plans = [state.solicitation_validate_plans_values];
      }
      if (state.solicitation_validate_suspend_line_values.length) {
        body.fields.suspend_line =
          state.solicitation_validate_suspend_line_values;
      }
      if (state.solicitation_validate_change_chip_values.length) {
        body.fields.change_chip =
          state.solicitation_validate_change_chip_values;
      }
      if (state.solicitation_validate_online_manager_values.length) {
        body.fields.update_gol =
          state.solicitation_validate_online_manager_values;
      }
      if (state.suspend_line_component_value.length) {
        body.fields.suspend_line = state.suspend_line_component_value;
      }
      if (state.online_manager_component_value.length) {
        body.fields.update_gol = state.online_manager_component_value;
      }
      const { data } = await axios.post("solicitation/validate/", {
        ...body
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      await commit("RESET_VALIDATE_SOLICITATION_STATE");
      commit("SET_STATUS_VALIDATE", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async approveSolicitation({ dispatch }, params) {
    try {
      const { data } = await axios.post("/solicitation/approve", params);
      if (data.success === false) {
        throw new Error(data.message);
      }
      dispatch("loadSelectedSolicitationValues", params.id_solicitation);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadWizard({ commit }, solicitation_type_id) {
    try {
      const { data } = await axios.get("/wizard", {
        params: solicitation_type_id
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_WIZARD_DATA", data);
      commit("setSolicitationForms", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSolicitationValidateFields({ commit }, solicitation_id) {
    try {
      const { data } = await axios.get(
        "/solicitation/validate/values/" + solicitation_id
      );
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_VALIDATE_FIELDS", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSolicitationFormEmail({ commit }, params) {
    try {
      commit("SET_SOLICITATION_FORM_EMAIL", {});
      const { data } = await axios.post("/solicitation/form-email", params);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_FORM_EMAIL", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSelectedSolicitationValues({ commit }, solicitation_id) {
    try {
      const { data } = await axios.get(
        "/solicitation/values/" + solicitation_id
      );
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_VALUES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadChipsByDDD({ commit }, ddd) {
    try {
      const { data } = await axios.get(
          "/solicitation/chips/available/", {
            params: { ddd }
          }
      );
      if (data.success === false) {
        throw new Error(data.message);
      }

      commit("SET_CHIP_VALUE", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadDataLocalStorage({ commit }, uniqueKeyStorage) {
    const item = localStorage.getItem(uniqueKeyStorage);
    return item ? JSON.parse(item) : null;
  },

  updateSolicitationValidateValues({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_VALUES", values);
  },
  updateSolicitationValidatePlansValues({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_PLANS_VALUES", values);
  },
  updateSolicitationValidatePackagesValues({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_PACKAGES_VALUES", values);
  },
  setVoluntaryLineSuspension({ commit }, values) {
    commit("SET_VOLUNTARY_LINE_SUSPENSION", values);
  },
  setActivateNewLine({ commit }, values) {
    commit("SET_ACTIVATE_NEW_LINE", values);
  },
  setCancelLineComponentValues({ commit }, values) {
    commit("SET_CANCEL_LINE_COMPONENT_VALUES", values);
  },
  setSuspendLineComponentValues({ commit }, values) {
    commit("SET_SUSPEND_LINE_COMPONENT_VALUES", values);
  },
  setChangeChipComponentValues({ commit }, values) {
    commit("SET_CHANGE_CHIP_COMPONENT_VALUES", values);
  },
  setAutomationComponentValues({ commit }, values) {
    commit("SET_AUTOMATION_COMPONENT_VALUES", values);
  },
  setOnlineManagerValues({ commit }, values) {
    commit("SET_ONLINE_MANAGER_COMPONENT_VALUES", values);
  },
  updateSolicitationValidateDDDValues({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_DDD_VALUES", values);
  },
  updateSolicitationValidateRemovePackagesValues({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_REMOVE_PACKAGES_VALUES", values);
  },
  updateSolicitationValidateCancelLine({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_CANCEL_LINE_VALUES", values);
  },
  updateSolicitationValidateSuspendLine({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_SUSPEND_LINE_VALUES", values);
  },
  updateSolicitationValidateChangeChip({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_CHANGE_CHIP_VALUES", values);
  },
  updateSolicitationValidateOnlineManager({ commit }, values) {
    commit("SET_SOLICITATION_VALIDATE_ONLINE_MANAGER_VALUES", values);
  },
  resetValidateSolicitationState({commit}) {
    commit("RESET_VALIDATE_SOLICITATION_STATE");
  }
};

const mutations = {
  //Necessario limpar as variaveis de validação para não enviar em novas solicitações
  RESET_VALIDATE_SOLICITATION_STATE(state) {
    state.solicitation_validate_plans_values = {};
    state.solicitation_validate_packages_values = {};
    state.solicitation_validate_ddd_values = {};
    state.solicitation_validate_remove_packages_values = [];
    state.solicitation_validate_cancel_line_values = [];
    state.solicitation_validate_suspend_line_values = [];
    state.solicitation_validate_change_chip_values = [];
    state.solicitation_validate_online_manager_values = [];
    state.package_component_value = [];
    state.packages_to_disable_component_values = [];
    state.cancel_line_component_value = [];
    state.suspend_line_component_value = [];
    state.change_chip_component_value = [];
    state.online_manager_component_value = [];
    state.reactive_line_component_value = [];
    state.voluntary_line_suspension_component_value = [];
    state.activate_new_line_component_value = [];
    state.solicitation_validate_values = {};
  },
  SET_SELECTED_SOLICITATION: (state, selectedSolicitation) => {
    state.selected_solicitation = selectedSolicitation;
  },
  SET_PROTOCOLS: (state, data) => {
    state.protocols = data;
  },
  SET_APPROVER: (state, approver) => {
    state.approvers = approver;
  },
  setFilter: (state, filter) => {
    state.filter = filter;
  },
  setSolicitationForms: (state, solicitation_data) => {
    state.solicitation_forms = solicitation_data;
  },
  SET_SOLICITATION: (state, solicitation) => {
    state.solicitation.data = solicitation.data.solicitation.data;
    state.total_items = solicitation.data.solicitation.total;
    state.total_pages = solicitation.data.solicitation.last_page;
    state.status_list = solicitation.data.status_list;
  },
  SET_DATA_EXCEL: (state, solicitations) => {
    state.dataExcel = solicitations.data.solicitation.data;
  },
  SET_IMEIS: (state, imeis) => {
    state.imeis = imeis;
  },
  SET_ACCOUNTS: (state, data) => {
    state.accounts = data.accounts;
  },
  SET_SOLICITATION_STATUS: (state, solicitation_status) => {
    state.solicitation_status = solicitation_status;
  },
  SET_SOLICITATION_VALIDATE_VALUES: (state, option) => {
    state.solicitation_validate_values[option.model] = option;
  },
  SET_SOLICITATION_VALIDATE_PLANS_VALUES: (state, plan) => {
    if (plan.plan_name) {
      state.solicitation_validate_plans_values[plan.plan_name] = plan.fields;
    } else {
      state.solicitation_validate_plans_values = {};
    }
  },
  SET_SOLICITATION_VALIDATE_PACKAGES_VALUES: (state, pacote) => {
    if (pacote.fields) {
      state.solicitation_validate_packages_values[pacote.index] =
        pacote.fields;
    } else {
      state.solicitation_validate_packages_values = {};
    }
  },
  SET_SOLICITATION_VALIDATE_PACKAGES_VALUE: (state, value) => {
    state.solicitation_validate_packages_values = value;
  },
  SET_SOLICITATION_VALIDATE_PACKAGES_VALUES_VALOR_PACOTE: (state, data) => {
    state.fields['busca_pacote'][data.index].valor_pacote = data.valor_pacote;
  },
  SET_SOLICITATION_VALIDATE_DDD_VALUES: (state, ddd) => {
    if (ddd) {
      state.solicitation_validate_ddd_values = ddd.fields;
    } else {
      state.solicitation_validate_ddd_values = {};
    }
  },
  SET_SOLICITATION_VALIDATE_REMOVE_PACKAGES_VALUES: (state, pacote) => {
    if (pacote) {
      state.solicitation_validate_remove_packages_values = pacote;
    } else {
      state.solicitation_validate_remove_packages_values = {};
    }
  },
  SET_SOLICITATION_VALIDATE_CANCEL_LINE_VALUES: (state, pacote) => {
    if (pacote) {
      state.solicitation_validate_cancel_line_values = pacote;
    } else {
      state.solicitation_validate_cancel_line_values = [];
    }
  },
  SET_SOLICITATION_VALIDATE_SUSPEND_LINE_VALUES: (state, pacote) => {
    if (pacote) {
      state.solicitation_validate_suspend_line_values = pacote;
    } else {
      state.solicitation_validate_suspend_line_values = [];
    }
  },
  SET_SOLICITATION_VALIDATE_CHANGE_CHIP_VALUES: (state, chipInfo) => {
    if (chipInfo) {
      state.solicitation_validate_change_chip_values = chipInfo;
    } else {
      state.solicitation_validate_change_chip_values = [];
    }
  },

  SET_SOLICITATION_VALIDATE_ONLINE_MANAGER_VALUES: (state, onlineManager) => {
    if (onlineManager) {
      state.solicitation_validate_online_manager_values = onlineManager;
    } else {
      state.solicitation_validate_online_manager_values = [];
    }
  },

  SET_WIZARD_DATA: (state, wizardData) => {
    state.wizard_data = wizardData;
  },
  SET_CHIP_VALUE: (state, data) => {
    state.chip_values = data;
  },
  SET_SOLICITATION_VALUES: (state, data) => {
    state.selected_solicitation_values = data;
  },
  SET_SOLICITATION_VALIDATE_FIELDS: (state, data) => {
    state.selected_solicitation_validate_fields = data;
  },
  SET_SOLICITATION_FORM_EMAIL: (state, data) => {
    state.solicitation_form_email = data;
  },
  SET_STATUS_VALIDATE: (state, data) => {
    state.solicitation_validate_status = data;
  },
  SET_VOLUNTARY_LINE_SUSPENSION: (state, line) => {
    state.voluntary_line_suspension_component_value = line;
  },
  SET_ACTIVATE_NEW_LINE: (state, data) => {
    state.activate_new_line_component_value = data;
  },
  SET_CANCEL_LINE_COMPONENT_VALUES: (state, packages) => {
    state.cancel_line_component_value = packages;
  },
  SET_SUSPEND_LINE_COMPONENT_VALUES: (state, packages) => {
    state.suspend_line_component_value = packages;
  },
  SET_CHANGE_CHIP_COMPONENT_VALUES: (state, packages) => {
    state.change_chip_component_value = packages;
  },
  SET_AUTOMATION_COMPONENT_VALUES: (state, value) => {
    state.solicitation_validate_values[value.automation] = value.data[value.automation];
  },
  SET_ONLINE_MANAGER_COMPONENT_VALUES: (state, packages) => {
    state.online_manager_component_value = packages;
  }
};

const getters = {
  getSolicitationById: (state) => (id) => {
    return state.solicitation.data.find((item) => item.id === id);
  },
  getSelectedSolicitation: (state) => {
    if (state.selected_solicitation.data) {
      return { ...state.selected_solicitation.data[0] };
    }
  },
  getSelectedSolicitationType: (state) => {
    if (state.selected_solicitation_type.data) {
      return {
        ...state.selected_solicitation_type.data[0].content.solicitation_type
      };
    }
  },
  getCompany: (state) => {
    if (state.selected_solicitation_type.data) {
      return { ...state.selected_solicitation_type.data[0].company_id };
    }
  },
  getFilter: (state) => {
    return state.filter;
  },
  getType: (state) => {
    return state.type;
  },
  getChips: (state) => {
    return state.chip_values;
  },
  getImeis: (state) => {
    return state.imeis;
  },
  getAccounts: (state) => {
    return state.accounts;
  },
  getApproveError: (state) => {
    return state.approve_error;
  },
  getApproveSuccess: (state) => {
    return state.approve_success;
  },
  getSelectedSolicitationValues: (state) => {
    return state.selected_solicitation_values.data;
  },
  getChangeChipComponentValue: (state) => {
    return state.change_chip_component_value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
